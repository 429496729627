import React from "react";
import {useIntl} from "react-intl";
import {Form, Select} from "antd";

const StaticSelect = ({
                          label = "",
                          name = "",
                          value = "",
                          placeholder = "",
                          required = true,
                          disabled = false,
                          addOn = "",
                          items = [],
                          index,
                          ...arg
                      }) => {
    const intl = useIntl();

    return (
        <Form.Item
            rules={[{required: required}]}
            name={index + 1 ? [index, name] : name}
            {...arg}
            extra={addOn === "" ? "" : intl.formatMessage({id: addOn})}
            label={label === "" ? "" : intl.formatMessage({id: label})}
            initialValue={value}
        >
            <Select
                showSearch={true}
                allowClear={true}
                placeholder="Select an option"
                filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
            >
                {
                    items?.map((item, index) =>
                        <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                }
            </Select>
        </Form.Item>
    )
}

export default StaticSelect;