import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import config from "../util/Config";

const SignIn = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(({auth}) => auth.authUser);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = values => {
        dispatch(userSignIn(values));
    };

    useEffect(() => {
        if (authUser !== null) {
            props.history.push('/');
        }
    }, [authUser]);

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">

                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo">
                            <img src={config.logoUrl} alt='Neature'/>
                        </div>
                    </div>

                    <div className="gx-app-login-content">
                        <Form
                            initialValues={{remember: true}}
                            // name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0 stech_form"
                        >

                            <Form.Item
                                label="Username Or Email"
                                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">

                                <Input placeholder="Email"/>
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                                <Input type="password" placeholder="Password"/>
                            </Form.Item>
                            {/*<Form.Item>*/}
                            {/*    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>*/}
                            {/*    <span className="gx-signup-form-forgot gx-link"><IntlMessages*/}
                            {/*        id="appModule.termAndCondition"/></span>*/}
                            {/*</Form.Item>*/}
                            <Form.Item>
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="app.userAuth.signIn"/>
                                </Button>
                                {/*<span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages*/}
                                {/*id="app.userAuth.signUp"/></Link>*/}
                            </Form.Item>
                        </Form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SignIn;
