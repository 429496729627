import React from "react";
import {Button, Popover, message} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import axiosInstance from "../../util/Api";
import {useMutation, useQueryClient} from "react-query";

const DeleteButton = ({recordID, deleteUrl, apiUrl, refetchQuery}) => {
    const queryClient = useQueryClient();

    const deleteItem = async (id) => {
        const { data: response } = await axiosInstance.delete(apiUrl + "/" + id);
        return response;
    };

    const { mutate: deleteMutate, isLoading } = useMutation(deleteItem, {
        onSuccess: (data) => {
            if (data.status === 1) {
                message.success(data.msg);
                // setVisible(false);
                // setDeletePopover(false);
                queryClient.refetchQueries([refetchQuery]);
            } else {
                message.error(data.msg);
            }
        },
        onError: () => {
            message.error("Server Error");
        },
    });

    const handleDelete = (id) => {
        deleteMutate(id);
    };

    return(
        <Popover
            placement="topLeft"
            trigger="click"
            content={
                <div className="chms__action__popover__content">
                    <p>Are you sure you want to delete this record?</p>
                    <div className="chms__action__popover__content__btns">
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => handleDelete(recordID)}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Yes
                        </Button>
                        <Button size="small">
                            No
                        </Button>
                    </div>
                </div>
            }
        >
            <Button
                size="small"
                type="danger"
                icon={<DeleteOutlined />}
                className="btn__delete"
            >
                Delete
            </Button>
        </Popover>
    );
}

export default DeleteButton;