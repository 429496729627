import React from 'react';
import Data from "./data.json";
import ListPage from "../../../../util/ListPage";

const CategoryList = () => {
    return(
        <ListPage
            jsonData={Data}
        />
    )
}

export default CategoryList