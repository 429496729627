import React, {useEffect, useState} from 'react';
import {Card, Col, Form, message, Row} from "antd";
import IntlMessages from "../../../util/IntlMessages";
import FormFields from "../../../util/FormFields";
import Data from './data.json';
import {Link, useHistory, useParams} from "react-router-dom";
import FormBtn from "../../../components/FormFields/FormBtn";
import CircularProgress from "../../../components/CircularProgress";
import axiosInstance from "../../../util/Api";
import {UnorderedListOutlined} from "@ant-design/icons";
import {NotificationContainer} from 'react-notifications'
import {useMutation, useQuery} from "react-query";
import Config from "../../../util/Config";
import {FinalDataWithImage} from "../../../util/Helper";

const NewCustomer = () => {
    const [jsonData, setJsonData] = useState(Data);
    const [form] = Form.useForm();
    const {id} = useParams();
    const history = useHistory();

    const apiCall = async (values) => {
        let url = Data?.urls?.apiUrl;
        if(id){
            url = Data?.urls?.apiUrl + "/" +id;
        }
        return await axiosInstance.post(url, values);
    }

    const {mutate, isLoading} = useMutation(apiCall, {
        onSuccess: ({data}) => {
            // console.log("data", data)
            if(data?.status === 1){
                history.push(Data?.urls?.list)
                message.success(data?.msg)
            }
            else {
                message.error(data?.msg)
            }
        },
        onError: ({response}) => {
            // console.log("error", response)
            if(response.status === 422){
                message.error(response.status + ": " + response.data.message)
            }
            else{
                message.error(response.status + ": " + response.statusText)
            }
        }
    })

    const getData = async () => {
        const {data} = await axiosInstance.get(Data?.urls?.apiUrl+"/"+id).then(res => res);
        return data;
    }

    const {data: editData, isLoading: isEditLoading} = useQuery([`${Data?.queryKey?.edit}`], getData, {
        onError: ({response}) => {
            message.error(response.status + ": " + response.statusText)
        },
        enabled: id ? true : false,
        refectOnMount: true
    })

    // useEffect(() => {
    //     const newData = { ...jsonData };
    //     const newArray = [...newData.inputFields[0].inputs].map(itm => {
    //         if (itm.name === "password") {
    //             itm.isShow = false;
    //             return itm;
    //         } else {
    //             return itm
    //         }
    //     })
    //     newData.inputFields[0].inputs = newArray;
    //     setJsonData(newData)
    // }, [])

    useEffect(() => {
        if (id !== undefined ) {
            const newData = { ...jsonData };
            if(editData?.data?.file_id !== null){
                form.setFieldsValue({
                    ...editData?.data,
                    image: [{
                        'id': editData?.data?.file_id,
                        'url': Config.BASE_URL+editData?.data?.path
                    }]
                })
            }
            else{
                form.setFieldsValue({
                    ...editData?.data,
                    image: []
                })
            }

            const newArray = [...newData.inputFields[0].inputs].map(itm => {
                if (itm.name === "password") {
                    itm.isShow = false;
                    return itm;
                } else {
                    return itm
                }
            })
            newData.inputFields[0].inputs = newArray;
            setJsonData(newData)
        }
        else {
            form.resetFields();
        }
    }, [id, editData]);

    const onFinish = (values) => {
        let data = {
            ...values,
            shop_id: 1
        }
        if(id) {
            data['_method'] = 'PATCH';
            if(data.image[0].id > 0){
                data.image = [];
            }
        }
        else{
            if(data.image === undefined){
                data.image = [];
            }
        }
        // console.log("Valuesf", data)
        let newData = FinalDataWithImage(data, ['image'])
        mutate(newData)
    }

    const onFinishFailed = (e) => {
        message.error("Required field is missing.")
    }

    if(isLoading){
        return <CircularProgress />
    }

    if(isEditLoading){
        return <CircularProgress />
    }

    return(

            <Card
                title={<IntlMessages id={id ? Data?.editTitle : Data?.addTitle} />}
                extra={<Link to={Data?.urls.list} className="card__link"><UnorderedListOutlined /> List</Link>}
            >
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="stech_form"
                    form={form}
                >
                    <Row>
                        <FormFields jsonData={jsonData?.inputFields}/>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormBtn />
                        </Col>
                    </Row>
                </Form>
                <NotificationContainer />
            </Card>
    );
}

export default NewCustomer;