import React from "react";
import {useIntl} from "react-intl";
import {Button, Form, Select} from "antd";
import {useQuery} from "react-query";
import axiosInstance from "../../util/Api";
import {ReloadOutlined} from "@ant-design/icons";

const ApiSelect = ({
                      label = "",
                      name = "",
                      value = null,
                      placeholder = "",
                      required = true,
                      disabled = false,
                      addOn = "",
                      apiUrl = '',
                      index,
                      ...arg
                    }) => {
    const intl = useIntl();

    const apiCall = async () => {
        if(apiUrl !== '') {
            const {data} = await axiosInstance.get(apiUrl).then(res => res.data)
            return data
        }
    }
    const {
        isLoading,
        isError,
        data
    } = useQuery([apiUrl], apiCall, {refetchOnMount: true});
    console.log(data)

    if(isError){
        return (<Form.Item
            rules={[{required: required}]}
            name={index + 1 ? [index, name] : name}
            {...arg}
            extra={addOn === "" ? "" : intl.formatMessage({id: addOn})}
            label={label === "" ? "" : intl.formatMessage({id: label})}
            initialValue={value}
        >

            <Select
                allowClear={true}
                placeholder={intl.formatMessage({id: placeholder})}
                style={{width: "100%"}}
                status="error"
                disabled={disabled}
                {...arg}
            >
                <Select.Option style={{textAlign: "center"}}>
                    <Button
                        type="link"
                        icon={<ReloadOutlined/>}
                        onClick={() => window.location.reload()}
                        className="reload_page">
                        {intl.formatMessage({id: "Something is wrong please reload the page"})}
                    </Button>
                </Select.Option>

            </Select>
        </Form.Item>
        )
    }

    return value === null ?
        <Form.Item
            rules={[{required: required}]}
            name={index + 1 ? [index, name] : name}
            {...arg}
            extra={addOn === "" ? "" : intl.formatMessage({id: addOn})}
            label={label === "" ? "" : intl.formatMessage({id: label})}
            // initialValue={value}
        >
            <Select
                showSearch={true}
                allowClear={true}
                placeholder="Select an option"
                filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                loading={isLoading}
                disabled={disabled}
            >
                {
                    data?.map((item, index) =>
                        <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                }
            </Select>
        </Form.Item>
        :
        <Form.Item
            rules={[{required: required}]}
            name={index + 1 ? [index, name] : name}
            {...arg}
            extra={addOn === "" ? "" : intl.formatMessage({id: addOn})}
            label={label === "" ? "" : intl.formatMessage({id: label})}
            initialValue={value}
        >
            <Select
                showSearch={true}
                allowClear={true}
                placeholder="Select an option"
                filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                loading={isLoading}
                disabled={disabled}
            >
                {
                    data?.map((item, index) =>
                        <Select.Option value={item.id}>{item.name}</Select.Option>
                    )
                }
            </Select>
        </Form.Item>
}

export default ApiSelect;