import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Form, message, Row, Select, Input, Popconfirm, Button} from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import FormFields from "../../../../util/FormFields";
import Data from './data.json';
import {Link, useHistory, useParams} from "react-router-dom";
import FormBtn from "../../../../components/FormFields/FormBtn";
import CircularProgress from "../../../../components/CircularProgress";
import axiosInstance from "../../../../util/Api";
import {DeleteOutlined, PlusOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {NotificationContainer} from 'react-notifications'
import {useMutation, useQuery} from "react-query";

const NewAttribute = () => {
    const [form] = Form.useForm();
    const {id} = useParams();
    const history = useHistory();
    const [attributeValue, setAttributeValues] = useState([
        {
            id: 0,
            value: undefined,
            details: undefined,
            status: 1
        },
    ]);

    const handleAddRow = () => {
        let item = {
            id: 0,
            value: undefined,
            details: undefined,
            status: 1
        };

        setAttributeValues([...attributeValue, item])
    }

    const handleChange = (e, index, field_name) => {
        let list = [...attributeValue];
        console.log(e)
        if(field_name === 'status'){
            list[index][field_name] = e
        }
        else {
            list[index][field_name] = e.target.value;
        }

        setAttributeValues(list)
    }

    const handleRowDelete = (id) => {
        if (attributeValue.length === 1) {
            message.warning("You can not delete this item");
        } else {
            let newArr = attributeValue.filter((item, index) => index !== id);
            setAttributeValues(newArr);
        }
    };

    const apiCall = async (values) => {
        if(id){
            return await axiosInstance.patch(`${Data?.urls?.apiUrl}/${id}`, values)
        }
        else {
            return await axiosInstance.post(Data?.urls?.apiUrl, values);
        }
    }

    const {mutate, isLoading} = useMutation(apiCall, {
        onSuccess: ({data}) => {
            // console.log("data", data)
            if(data?.status === 1){
                history.push(Data?.urls?.list)
                message.success(data?.msg)
            }
            else {
                message.error(data?.msg)
            }
        },
        onError: ({response}) => {
            // console.log("error", response)
            if(response.status === 422){
                message.error(response.status + ": " + response.data.message)
            }
            else{
                message.error(response.status + ": " + response.statusText)
            }
        }
    })

    const getData = async () => {
        const {data} = await axiosInstance.get(Data?.urls?.apiUrl+"/"+id).then(res => res);
        return data;
    }

    const {data: editData, isLoading: isEditLoading} = useQuery([`${Data?.queryKey?.edit}`], getData, {
        // onSuccess: (data) => {
        //     console.log("mainData", data)
        //     if(data?.status === 1) {
        //         setListData(data?.data)
        //     }
        // },
        onError: ({response}) => {
            message.error(response.status + ": " + response.statusText)
        },
        enabled: id ? true : false,
        refectOnMount: true
    })

    useEffect(() => {
        if (id !== undefined ) {
            form.setFieldsValue({
                ...editData?.data
            })
            let items = [];
            editData?.data?.values?.map((item, index) => {
                items.push({
                    id: item?.id,
                    value: item?.value,
                    details: item?.details,
                    status: item?.status
                })
            })

            setAttributeValues(items)
        }
    }, [id, editData]);


    const onFinish = (values) => {
        // console.log(values)
        let data = {
            ...values,
            shop_id: 1,
            values: attributeValue
        }
        mutate(data)
    }

    const onFinishFailed = (e) => {
        message.error("Required field is missing.")
    }

    if(isLoading){
        return <CircularProgress />
    }

    if(isEditLoading){
        return <CircularProgress />
    }

    return(

            <Card
                title={<IntlMessages id={id ? Data?.editTitle : Data?.addTitle} />}
                extra={<Link to={Data?.urls.list} className="card__link"><UnorderedListOutlined /> List</Link>}
            >
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="stech_form"
                    form={form}
                    // onFieldsChange={onFieldChange}
                    // layout="vertical"
                >
                    <Row>
                        <FormFields jsonData={Data?.inputFields}/>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider orientation="left">Attribute Values</Divider>
                            <Button
                                type="primary"
                                icon={<PlusOutlined/>}
                                style={{margin: 0}}
                                onClick={() => handleAddRow()}
                            >
                                Add More
                            </Button>
                        </Col>
                        <Col span={24}>
                            <table className="custom__table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "2%" }}>Action</th>
                                        <th>Value</th>
                                        <th>Details</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    attributeValue?.map((item, index) =>
                                        <tr>
                                            <td>
                                                <Popconfirm
                                                    title="Sure to delete?"
                                                    onConfirm={() => handleRowDelete(index)}
                                                >
                                                    <Button size="small" type="danger">
                                                        <DeleteOutlined/>
                                                    </Button>
                                                </Popconfirm>
                                            </td>
                                            <td>
                                                <Input
                                                    name="value"
                                                    className="gx-w-100"
                                                    value={item.value}
                                                    placeholder="Enter value"
                                                    onChange={(e) => handleChange(e, index, "value")}
                                                />
                                            </td>
                                            <td>
                                                <Input.TextArea
                                                    name="description"
                                                    rows={1}
                                                    className="gx-w-100"
                                                    value={item.details}
                                                    placeholder="Enter description"
                                                    onChange={(e) => handleChange(e, index, "details")}
                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    showSearch
                                                    placeholder="Select an item"
                                                    style={{width: "100%"}}
                                                    filterOption={(input, option) =>
                                                        option.children
                                                            .toString()
                                                            .toLowerCase()
                                                            .indexOf(input.toString().toLowerCase()) >= 0
                                                    }
                                                    allowClear={true}
                                                    onChange={(e) => handleChange(e, index, "status")}
                                                    value={item.status}
                                                >
                                                    <Select.Option value={1}>Active</Select.Option>
                                                    <Select.Option value={0}>Inactive</Select.Option>
                                                </Select>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                        <Col span={24}>
                            <FormBtn />
                        </Col>
                    </Row>
                </Form>
                <NotificationContainer />
            </Card>
    );
}

export default NewAttribute;