import React, {useEffect, useState} from 'react';
import {Card, Col, Form, message, Row} from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import FormFields from "../../../../util/FormFields";
import Data from './data.json';
import {Link, useHistory, useParams} from "react-router-dom";
import FormBtn from "../../../../components/FormFields/FormBtn";
import CircularProgress from "../../../../components/CircularProgress";
import axiosInstance from "../../../../util/Api";
import {UnorderedListOutlined} from "@ant-design/icons";
import {NotificationContainer} from 'react-notifications'
import {useMutation, useQuery} from "react-query";

const NewPage = () => {
    const [form] = Form.useForm();
    const {id} = useParams();
    const history = useHistory();

    const apiCall = async (values) => {
        if(id){
            return await axiosInstance.patch(`${Data?.urls?.apiUrl}/${id}`, values)
        }
        else {
            return await axiosInstance.post(Data?.urls?.apiUrl, values);
        }
    }

    const {mutate, isLoading} = useMutation(apiCall, {
        onSuccess: ({data}) => {
            // console.log("data", data)
            if(data?.status === 1){
                history.push(Data?.urls?.list)
                message.success(data?.msg)
            }
            else {
                message.error(data?.msg)
            }
        },
        onError: ({response}) => {
            // console.log("error", response)
            if(response.status === 422){
                message.error(response.status + ": " + response.data.message)
            }
            else{
                message.error(response.status + ": " + response.statusText)
            }
        }
    })

    const getData = async () => {
        const {data} = await axiosInstance.get(Data?.urls?.apiUrl+"/"+id).then(res => res);
        return data;
    }

    const {data: editData, isLoading: isEditLoading} = useQuery([`${Data?.queryKey?.edit}`], getData, {
        // onSuccess: (data) => {
        //     console.log("mainData", data)
        //     if(data?.status === 1) {
        //         setListData(data?.data)
        //     }
        // },
        onError: ({response}) => {
            message.error(response.status + ": " + response.statusText)
        },
        enabled: id ? true : false,
        refectOnMount: true
    })

    useEffect(() => {
        if (id !== undefined ) {
            form.setFieldsValue({
                ...editData?.data
            })
        }
    }, [id, editData]);


    const onFinish = (values) => {
        // console.log(values)
        let data = {
            ...values,
            shop_id: 1
        }
        mutate(data)
    }

    const onFinishFailed = (e) => {
        message.error("Required field is missing.")
    }

    if(isLoading){
        return <CircularProgress />
    }

    if(isEditLoading){
        return <CircularProgress />
    }

    return(

            <Card
                title={<IntlMessages id={id ? Data?.editTitle : Data?.addTitle} />}
                extra={<Link to={Data?.urls.list} className="card__link"><UnorderedListOutlined /> List</Link>}
            >
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="stech_form"
                    form={form}
                    // onFieldsChange={onFieldChange}
                    // layout="vertical"
                >
                    <Row>
                        <FormFields jsonData={Data?.inputFields}/>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormBtn />
                        </Col>
                    </Row>
                </Form>
                <NotificationContainer />
            </Card>
    );
}

export default NewPage;