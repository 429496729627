import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

const UserInfo = () => {

    const dispatch = useDispatch();

    const userMenuOptions = (<ul className="gx-user-popover">
            <li>My Account</li>
            <li>Connections</li>
            <li onClick={() => dispatch(userSignOut())}>Logout
            </li>
        </ul>);

    return (<div className="gx-flex-row gx-align-items-center gx-avatar-row">
            <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
                <Avatar src={"https://via.placeholder.com/150"} className="gx-size-50 gx-pointer" alt=""/>
                <span className="gx-avatar-name">Rob Farnandies<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
            </Popover>
        </div>);
};

export default UserInfo;
