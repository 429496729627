import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CustomerList from "../../containers/STech/Customer/CustomerList";
import NewCustomer from "../../containers/STech/Customer/NewCustomer";

const Product = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/customers`} component={CustomerList} />
        <Route path={`${match.url}/newcustomer`} component={NewCustomer} />
        <Route path={`${match.url}/editcustomer/:id`} component={NewCustomer} />
    </Switch>
);

export default Product;