import React from "react";
import {Button, Popover} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

const EditButton = ({recordID, editUrl}) => {
    return(
        <Link to={editUrl+recordID}>
            <Button
                size="small"
                type="primary"
                className="btn__edit"
                icon={<EditOutlined />}
            >
                Edit
            </Button>
        </Link>
    );
}

export default EditButton;