import React from "react";
import {Form, Radio} from "antd";
import {useIntl} from "react-intl";

const RadioField = ({
    label="",
    name="",
    placeholder="",
    required=true,
    disabled=false,
    addOn="",
    items = [],
    value = 1,
    index,
    ...arg
}) => {

    const intl = useIntl();
    return(
        <Form.Item
            label={label === "" ? "" : intl.formatMessage({ id: label })}
            name={index+1 ? [index, name ] : name}
            rules={[{ required: required }]}
            extra={addOn === "" ? "" : intl.formatMessage({ id: addOn })}
            {...arg}
            initialValue={value}
        >
        <Radio.Group>
            {
                items?.map((item, index) =>
                    <Radio value={item.id}>{item.name}</Radio>
                )
            }
        </Radio.Group>
        </Form.Item>
    )
}

export default RadioField;