import {
    CaretLeftOutlined,
    CheckCircleFilled,
    ReloadOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { checkPropTypes } from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

const FormBtn = ({isLoading, isShowResetSubmitBtn = true, isShowBackButton = true }) => {
    const history = useHistory();
    return (
        <div className="submit__btn">
            {/*<Button icon={<CloseCircleOutlined />} onClick={history.goBack}>*/}
            {
                isShowBackButton && <Button icon={<CaretLeftOutlined />} onClick={history.goBack}>
                    Back
                </Button>
            }

            <Button icon={<ReloadOutlined />} htmlType="reset">
                Reset
            </Button>
            <Button
                type="primary"
                icon={<CheckCircleFilled />}
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
            >
                Submit
            </Button>
        </div>
    );
};


export default FormBtn;
