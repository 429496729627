import React from 'react';
import Data from "./data.json";
import ListPage from "../../../util/ListPage";

const CustomerList = () => {
    return(
        <ListPage
            jsonData={Data}
        />
    )
}

export default CustomerList;