import React from 'react';
import InputText from "./InputText";
import {Col, InputNumber} from "antd";
import StaticSelect from "./StaticSelect";
import TextArea from "./TextArea";
import ApiSelect from "./ApiSelect";
import RadioField from "./RadioField";
import NumberInput from "./InputNumber";
import InputPassword from "./InputPassword";
import FileUpload from "./FileUpload";

const InputFields = ({input, type}) => {
    console.log(input)
    const getFields = (item) => {
        if (item.type === 'text') {
            return <InputText label={item.label} name={item.name} placeholder={item.placeholder}
                              required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}/>
        } else if (item.type === 'textarea') {
            return <TextArea label={item.label} name={item.name} placeholder={item.placeholder} value={item.value}
                             rows={item.rows} required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}/>
        } else if (item.type === 'apiSelect') {
            return <ApiSelect label={item.label} name={item.name} value={item.value} placeholder={item.placeholder}
                                 required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}
                                 apiUrl={item.url}/>
        } else if (item.type === 'staticSelect') {
            return <StaticSelect label={item.label} name={item.name} value={item.value} placeholder={item.placeholder}
                                 required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}
                                 items={item.items}/>
        } else if (item.type === 'radio') {
            return <RadioField label={item.label} name={item.name} value={item.initialValue} placeholder={item.placeholder}
                                 required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}
                                 items={item.items}/>
        } else if (item.type === 'number') {
                return <NumberInput label={item.label} name={item.name} value={item.initialValue} placeholder={item.placeholder}
                                  required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}/>
        } else if (item.type === 'password'){
            return <InputPassword label={item.label} name={item.name} placeholder={item.placeholder}
                              required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}/>
        } else if (item.type === 'file'){
            return <FileUpload label={item.label} name={item.name} required={type === 'search' ? false : item.required}
                               disabled={item.disabled} max={item.max}/>
        } else if(item.type === 'url'){
            return <InputText label={item.label} name={item.name} placeholder={item.placeholder}
                                  required={type === 'search' ? false : item.required} disabled={item.disabled} addOn={item.addon}/>
        }
    }

    return (
        input?.map((item, index) =>
            type ? item.isShow &&
                <Col span={24} lg={item.size} key={index}>
                    {getFields(item)}
                </Col>
                :
                item.isShow && item.only !== 'filter' &&
                <Col span={24} lg={item.size} key={index}>
                    {getFields(item)}
                </Col>
        )
    );
}

export default InputFields;