import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import Product from "./Product";
import Customer from "./Customer";
import CMS from "./CMS";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/*<Route path={`${match.url}product`} component={asyncComponent(() => import('./Product'))}/>*/}
        <Route path={`${match.url}product`} component={Product} />
        <Route path={`${match.url}customer`} component={Customer} />
        <Route path={`${match.url}cms`} component={CMS} />
    </Switch>
  </div>
);

export default App;
