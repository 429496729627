module.exports = {
    footerText: 'Copyright Company Name © 2018',
    logoUrl: '/assets/images/logo2.png',
    loader: '/assets/images/logo2.png',

    // API_URL: 'http://localhost:8000/api/',
    // BASE_URL: 'http://localhost:8000',
    // FILE_URL: 'http://localhost:8000',
    // FRONTEND_URL: 'localhost://3000',

    API_URL: 'https://aaranyakexclusive.com/api/',
    BASE_URL: 'https://aaranyakexclusive.com',
    FILE_URL: 'https://aaranyakexclusive.com',
    FRONTEND_URL: 'https://admin-portal.aaranyakexclusive.com/',

}
