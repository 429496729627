// import axios from 'axios';
//
// export default axios.create({
//     // baseURL: "http://g-axon.work/jwtauth/api",
//     apiServer: 'http://127.0.0.1:8000/api',
//     baseURL: 'http://127.0.0.1:8000/',
//
//     headers: {
//         'Content-Type': 'application/json',
//     }
// });

import axios from 'axios';
import Config from './Config';

const token = localStorage.getItem('token');

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFToken";

axios.defaults.withCredentials = true;
const axiosInstance = axios.create({
    baseURL: Config.API_URL,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer '+token,
        'Authorization': token,
    }
});

export default axiosInstance;


export const headers = {
    'Authorization': token
}