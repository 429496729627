import React from "react";
import {useIntl} from "react-intl";
import {Form, Input, InputNumber} from "antd";

const NumberInput = ({
     label="",
     name="",
     placeholder="",
     required=true,
     disabled=false,
     addOn="",
     value = 0,
     index,
     ...arg
}) => {
    const intl = useIntl();

    return (
        <>
            <Form.Item
                label={label === "" ? "" : intl.formatMessage({ id: label })}
                name={index+1 ? [index, name ] : name}
                rules={[{ required: required }]}
                extra={addOn === "" ? "" : intl.formatMessage({ id: addOn })}
                initialValue={value}
                {...arg}
            >
                <InputNumber className="gx-w-100"
                    disabled={disabled}
                    placeholder={placeholder === "" ? "" : intl.formatMessage({ id: placeholder })}
                />
            </Form.Item>
        </>
    )
}

export default NumberInput;