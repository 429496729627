import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PageList from "../../containers/STech/CMS/Page/PageList";
import NewPage from "../../containers/STech/CMS/Page/NewPage";
import BannerList from "../../containers/STech/CMS/Banner/BannerList";
import NewBanner from "../../containers/STech/CMS/Banner/NewBanner";
import WidgetList from "../../containers/STech/CMS/Widget/WidgetList";
import NewWidget from "../../containers/STech/CMS/Widget/NewWidget";

const Product = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/pages`} component={PageList} />
        <Route path={`${match.url}/newpage`} component={NewPage} />
        <Route path={`${match.url}/editpage/:id`} component={NewPage} />
        <Route path={`${match.url}/banners`} component={BannerList} />
        <Route path={`${match.url}/newbanner`} component={NewBanner} />
        <Route path={`${match.url}/editbanner/:id`} component={NewBanner} />
        <Route path={`${match.url}/widgets`} component={WidgetList} />
        <Route path={`${match.url}/newwidget`} component={NewWidget} />
        <Route path={`${match.url}/editwidget/:id`} component={NewWidget} />
    </Switch>
);

export default Product;