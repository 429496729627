import React, {useState} from 'react';
import {Button, Card, Col, Collapse, Form, message, Popover, Row, Table, Tag} from "antd";
import IntlMessages from "./IntlMessages";
import FormFields from "./FormFields";
import FormBtn from "../components/FormFields/FormBtn";
import CircularProgress from "../components/CircularProgress";
import {
    DeleteOutlined,
    EditOutlined,
    MoreOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import axiosInstance from "./Api";
import {useQuery} from "react-query";
import {Link} from "react-router-dom";
import Action from "../components/ActionMenu/Action";
import SearchFormButton from "../components/FormFields/SearchFormButton";
import Data from "../containers/STech/Product/Category/data.json";

const ListPage = ({jsonData, isShowSearch = true, isEditShow= true, isDeleteShow= true}) => {
    const [form] = Form.useForm();
    const [Data, setData] = useState(jsonData);
    const [currentPage, setCurrentPage] = useState(1);
    // const [url, setUrl] = useState(Data?.urls?.apiUrl+"?page=1");
    const [url, setUrl] = useState(Data?.urls?.apiUrl);
    const [listData, setListData] = useState([]);

    const handlePagination = (page, pageSize) => {
        setCurrentPage(page)
        //****** old
        // let newUrl = Data?.urls?.apiUrl+"?page="+page;
        // let queryStringArray = url.split("?")[1].split("&");
        // if(queryStringArray.length > 1){
        //     queryStringArray.shift();
        //     newUrl = Data?.urls?.apiUrl+"?page="+page + "&" + queryStringArray.join("&")
        // }

        //**** New
        // console.log("queryStringArray", url)
        let newUrl = "";
        if(url.includes("?")) {
            let queryStringArray = url.split("?")[1];
            if(queryStringArray.includes("page")){
                const urlParams = new URLSearchParams(queryStringArray);
                urlParams.set('page', page)
                newUrl = Data?.urls?.apiUrl + "?" + urlParams.toString();
            }
            else {
                newUrl = url + "&page="+page;
            }
        }
        else {
            newUrl = Data?.urls?.apiUrl + "?page=" + page;
        }
        setUrl(newUrl)
    }

    const apiCall = async () => {
        const {data} = await axiosInstance.get(url).then(res => res);
        return data;
    }

    const {data, isError, isLoading} = useQuery([`${Data?.queryKey?.list}`, url], apiCall, {
        onSuccess: (data) => {
            console.log("mainData", data)
            if(data?.status === 1) {
                setListData(data?.data)
            }
        },
        onError: ({response}) => {
            message.error(response.status + ": " + response.statusText)
        },
        enabled: true,
        refectOnMount: false
    })


    let inputs = []
    Data?.inputFields?.map((item, index) => {
        const data = item.inputs.filter((i) => i.isFilterable);
        return inputs.push({inputs: data});
    })
    // console.log(inputs);


    let columns = [];
    Data?.columns?.map((item, index) => {
        if(item.title === 'Action'){
            columns.push({
                title: <IntlMessages id={item.title}/>,
                dataIndex: item.key,
                key: item.key,
                render: (text, all, index) =>
                    <Action
                        recordID={text}
                        isEditShow={isEditShow}
                        isDeleteShow={isDeleteShow}
                        editURL={Data?.urls?.edit}
                        deleteURL={Data?.urls?.edit}
                        apiURL={Data?.urls?.apiUrl}
                        refetchQuery={Data?.queryKey?.list}
                    />
            })
        }
        else if(item.title === 'SL No'){
            columns.push({
                title: <IntlMessages id={item.title} />,
                dataIndex: item.key,
                key: item.key,
                render: (text, all, index) => index + 1 + (currentPage - 1) * 10,
            });
        }
        else if(item.key === 'attribute_values'){
            columns.push({
                title: <IntlMessages id={item.title}/>,
                dataIndex: item.key,
                key: item.key,
                render: (text, all, index) => {
                    let values = []
                    text?.map((item, index) => values.push(item?.value))

                    return values.join(",");
                },
            });
        }
        else if (item.type === 'url'){
            columns.push({
                title: <IntlMessages id={item.title}/>,
                dataIndex: item.key,
                key: item.key,
                render: (text, all, index) => <Link to={`${item.url}${all.id}`}>{text}</Link>,
            });
        }
        else if(item.key === 'status'){
            if (item.type === 'product_status'){
                columns.push({
                    title: <IntlMessages id={item.title}/>,
                    dataIndex: item.key,
                    key: item.key,
                    render: (text, all, index) => {
                        if (text === 1) {
                            return (<Tag color="green" className="btn__status">Publish</Tag>);
                        } else if (text === 0) {
                            return (<Tag color="red" className="btn__status">Draft</Tag>)
                        }
                    },
                });
            }
            else {
                columns.push({
                    title: <IntlMessages id={item.title}/>,
                    dataIndex: item.key,
                    key: item.key,
                    render: (text, all, index) => {
                        if (text === 1) {
                            return (<Tag color="green" className="btn__status">Active</Tag>);
                        } else if (text === 0) {
                            return (<Tag color="red" className="btn__status">Inactive</Tag>)
                        }
                    },
                });
            }
        }
        else {
            columns.push({
                title: <IntlMessages id={item.title}/>,
                dataIndex: item.key,
                key: item.key,
                // render: (text, all, index) => (
                //     <Checkbox onChange={(e) => rowSelection(e, all.id)} />
                // ),
            });
        }
    })

    const onFinish = (values) => {
        console.log(values)
        let str = [];
        for(let p in values){
            if(values.hasOwnProperty(p) && values[p] !== undefined && values[p] !== ''){
                str.push(encodeURIComponent(p)+"="+encodeURIComponent(values[p]))
            }
        }
        console.log("lo", str.join("&"))
        // history.push('/product/categories?'+str.join("&"))
        // setUrl(Data?.urls?.apiUrl+"?page="+currentPage+"&"+str.join("&"))
        setCurrentPage(1)
        setUrl(Data?.urls?.apiUrl+"?"+str.join("&"))
    }

    return(
        <>
            {isShowSearch &&
                <Collapse accordin defaultActiveKey={[1]} expandIconPosition="end">
                    <Collapse.Panel header={<IntlMessages id="Advance Search"/>} key="1" className='avd__search'>
                        <Form
                            onFinish={onFinish}
                            className="stech_form"
                            form={form}
                        >
                            <Row gutter={24}>
                                <Col span={20}>
                                    <Row>
                                        {
                                            inputs?.length > 0 &&
                                            <FormFields jsonData={inputs} type="search"/>
                                        }
                                    </Row>
                                </Col>

                                <Col span={4}>
                                    <SearchFormButton />
                                </Col>
                            </Row>
                        </Form>
                    </Collapse.Panel>
                </Collapse>
            }

            <Card
                title={<IntlMessages id={Data?.listTitle} />}
                style={{ marginTop: "20px"}}
                extra={<Link to={Data?.urls?.add} className="card__link"><PlusOutlined /> Add</Link>}
            >
                    <Table
                        columns={columns}
                        dataSource={listData?.data}
                        loading={{
                            spinning: isLoading,
                            size: "large",
                            indicator: <CircularProgress />
                        }}
                        pagination={{
                            current: currentPage,
                            total: listData?.total,
                            defaultPageSize: listData?.per_page,
                            onChange: (page, pageSize) => handlePagination(page, pageSize),
                        }}
                    />
            </Card>
        </>
    )
}

export default ListPage;