import {CheckCircleFilled, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const SearchFormButton = () => {
    return (
        <div className="btn__search">
            <Button icon={<ReloadOutlined />} htmlType="reset">
                Reset
            </Button>
            <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
            >
                Search
            </Button>
        </div>
    );
};

export default SearchFormButton;
