import React, {useEffect, useState} from 'react';
import {Card, Col, Divider, Form, message, Row, Select} from "antd";
import IntlMessages from "../../../../util/IntlMessages";
import FormFields from "../../../../util/FormFields";
import Data from './data.json';
import {Link, useHistory, useParams} from "react-router-dom";
import FormBtn from "../../../../components/FormFields/FormBtn";
import CircularProgress from "../../../../components/CircularProgress";
import axiosInstance from "../../../../util/Api";
import {UnorderedListOutlined} from "@ant-design/icons";
import {NotificationContainer} from 'react-notifications'
import {useMutation, useQuery} from "react-query";
import ApiSelect from "../../../../components/FormFields/ApiSelect";


const NewProduct = () => {
    const [form] = Form.useForm();
    const {id} = useParams();
    const history = useHistory();
    const [jsonData, setJsonData] = useState(Data);
    const [showVariation, setShowVariation] = useState(false);
    const [variationOptions, setVariationOptions] = useState([]);

    const apiCall = async (values) => {
        if(id){
            return await axiosInstance.post(`${Data?.urls?.apiUrl}/${id}`, values)
        }
        else {
            return await axiosInstance.post(Data?.urls?.apiUrl, values);
        }
    }

    const {mutate, isLoading} = useMutation(apiCall, {
        onSuccess: ({data}) => {
            // console.log("data", data)
            if(data?.status === 1){
                history.push(Data?.urls?.list)
                message.success(data?.msg)
            }
            else {
                message.error(data?.msg)
            }
        },
        onError: ({response}) => {
            // console.log("error", response)
            if(response.status === 422){
                message.error(response.status + ": " + response.data.message)
            }
            else{
                message.error(response.status + ": " + response.statusText)
            }
        }
    })

    const getData = async () => {
        const {data} = await axiosInstance.get(Data?.urls?.apiUrl+"/"+id).then(res => res);
        return data;
    }

    const {data: editData, isLoading: isEditLoading} = useQuery([`${Data?.queryKey?.edit}`], getData, {
        onError: ({response}) => {
            message.error(response.status + ": " + response.statusText)
        },
        enabled: id ? true : false,
        refectOnMount: true
    })


    useEffect(() => {
        if (id !== undefined ) {
            form.setFieldsValue({
                ...editData?.data
            })
            const newData = {...jsonData};
            // console.log("editData", editData)
            if (editData?.data?.product_type === 2) {
                // console.log("ed", editData)
                setShowVariation(true)
                axiosInstance.get('/product/attribute/' + editData?.data?.attribute_id)
                    .then(res => {
                        if (res?.data?.status === 1) {
                            // console.log("att",res?.data?.data)

                            setVariationOptions(res?.data?.data['values'])
                            // setOptionDisable(false)
                        }
                    })
            }
            else{
                setShowVariation(false)
            }

            if (editData?.data?.category_id > 0) {
                newData.inputFields[1].inputs = [...newData.inputFields[1].inputs].map(itm => {
                    if (itm.name === "subcategory_id") {
                        itm.url = '/product/subcategory?isDrop=1&category_id=' + editData?.data?.category_id;
                        itm.disabled = false;
                        return itm;
                    } else {
                        return itm
                    }
                });
            }

        }
    }, [id, editData]);


    const onFinish = (values) => {
        console.log("values",values)
        let data = {
            ...values,
            shop_id: 1,
        }
        console.log("values...", data)
        // mutate(data)
        const formd = new FormData();
        for(let i in data) {
           // console.log("Values1", i)
            if(i === 'gallery'){
                if(data[i] !== undefined) {
                    if (data[i].length > 0) {
                        data[i].forEach((file) => {
                            // formData.append('files[]', file);
                            formd.append('gallery[]', file.originFileObj)
                        });
                    }
                }
            }
            else if(i === 'thumbnail'){
                if(data[i].length > 0){
                    formd.append('thumbnail', data[i][0].originFileObj)
                }
            }
            else if(i === 'variations') {
                if (data[i].length > 0) {
                    data[i].forEach((item) => {
                        formd.append('variations[]', item)
                    });
                }
            }
            else {
                formd.append(i, data[i])
            }
       }
        console.log("values.....", formd)
        if (id !== undefined ) {
            formd.append("_method", 'PATCH')
        }
        mutate(formd)
    }

    const onFinishFailed = (e) => {
        message.error("Required field is missing.")
    }

    const onFieldChange = (e) => {

        if(e['product_type']){
            if(e['product_type'] === 2){
                setShowVariation(true)
            }
            else{
                setShowVariation(false)
            }
        }
        else if (e['category_id']) {
            form.setFieldValue('subcategory_id', undefined);
            const newData = { ...jsonData };
            if (e['category_id'] > 0) {
                newData.inputFields[1].inputs = [...newData.inputFields[1].inputs].map(itm => {
                    if (itm.name === "subcategory_id") {
                        itm.url = '/product/subcategory?isDrop=1&category_id=' + e['category_id'];
                        itm.disabled = false;
                        return itm;
                    } else {
                        return itm
                    }
                });
            } else {
                newData.inputFields[1].inputs = [...newData.inputFields[1].inputs].map(itm => {
                    if (itm.name === "subcategory_id") {
                        itm.url = "";
                        itm.disabled = true;
                        return itm;
                    } else {
                        return itm
                    }
                });
            }
            setJsonData(newData)
        }
        else if(e['attribute_id']){
                form.setFieldValue('variation_options', undefined)
                axiosInstance.get('/product/attribute/'+e['attribute_id'])
                    .then(res => {
                        if(res?.data?.status === 1){
                            setVariationOptions(res?.data?.data['values'])
                        }
                    })
            }
    }

    if(isLoading){
        return <CircularProgress />
    }

    if(isEditLoading){
        return <CircularProgress />
    }

    return(

            <Card
                title={<IntlMessages id={id ? Data?.editTitle : Data?.addTitle} />}
                extra={<Link to={Data?.urls.list} className="card__link"><UnorderedListOutlined /> List</Link>}
            >
                <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="stech_form"
                    form={form}
                    // onFieldsChange={onFieldChange}
                    onValuesChange={onFieldChange}
                    // layout="vertical"
                >
                    <Row>
                        <FormFields jsonData={Data?.inputFields}/>
                    </Row>
                    {
                        showVariation &&
                        <Row>
                            <Divider orientation="left">Variation Options</Divider>
                            <Col lg={8} md={12} sm={12} xs={12}>
                                <ApiSelect label="Attribute" name="attribute_id" placeholder="Please select an option"
                                           required={true} disabled={false} apiUrl='/product/attribute?isDrop=1'/>
                            </Col>

                            <Col lg={8} md={12} sm={12} xs={12}>
                                <Form.Item
                                    rules={[{required: true}]}
                                    name="variations"
                                    label="Variation Options"
                                >
                                    <Select
                                        mode="multiple"
                                        showSearch={true}
                                        allowClear={true}
                                        placeholder="Select options"
                                        filterOption={(input, option) => option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                        // disabled={optionDisable}
                                        // onChange={handleMulti}
                                    >
                                        {
                                            variationOptions?.map((item, index) =>
                                                <Select.Option value={item.value}>{item.value}</Select.Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col span={24}>
                            <FormBtn />
                        </Col>
                    </Row>
                </Form>
                <NotificationContainer />
            </Card>
    );
}

export default NewProduct;