import React, {useState} from "react";
import {useIntl} from "react-intl";
import {Form, message, Modal, Upload} from "antd";
import axiosInstance from "../../util/Api";
import CircularProgress from "../CircularProgress";

const FileUpload = ({
                         label="",
                         name="",
                         required=true,
                         max = 0,
                         index,
                         ...arg
                     }) => {

    const intl = useIntl();
    const [images, setImages] = useState([]);
    const [prevImg, setPrevImg] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const normFile = (e) => {
        console.log("previewN", e)
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/JPEG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            message.error('Image must smaller than 1MB!');
        }
        console.log("preview2", file)
        setImages([... images, file])
        return false;
    }

    const imagePreview = async (file) => {
        console.log("preview",images)
        if(file.url) {
            setPrevImg(file.url)
        } else {
            let base64 = await getBase64(file.originFileObj);
            setPrevImg(base64)
        }
        setIsModalOpen(true);
    }

    const deleteImage = (file) => {
        console.log("previewD", file.url);
        if(file?.url){
            setLoader(true)
            axiosInstance.delete('common/deletefile/'+file?.id)
                .then(res => {
                    if(res.data.status === 1){
                        message.success(res.data.msg)
                        let newFileLists = images.filter((item, index) => item.id !== file?.id)
                        setImages(newFileLists)
                    }
                    else{
                        message.error(res?.data?.msg)
                    }
                    setLoader(false)
                })
                .catch(error => {
                    setLoader(false)
                    console.log(error)
                });
        }
        else{
            let newFileLists = images.filter((item, index) => item.uid !== file?.uid)
            setImages(newFileLists)
        }
    }

    if(loader){
        return <CircularProgress />;
    }

    return (
        <>
            <Form.Item
                name={index+1 ? [index, name ] : name}
                label={label === "" ? "" : intl.formatMessage({ id: label })}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: required }]}
                {...arg}
            >
                <Upload
                    maxCount={max}
                    listType="picture-card"
                    className="avatar-uploader"
                    beforeUpload={beforeUpload}
                    onRemove={deleteImage}
                    onPreview={imagePreview}
                    // onChange={handleImageChange}
                >
                    <div style={{marginTop: 8}}>Upload</div>
                </Upload>
            </Form.Item>

            <Modal title="Image Preview" open={isModalOpen} onCancel={handleCancel} footer={false}>
                <img src={prevImg} />
            </Modal>
        </>
    )
}

export default FileUpload;