import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CategoryList from './../../containers/STech/Product/Category/CategoryList';
import NewCategory from './../../containers/STech/Product/Category/NewCategory';
import NewSubCategory from "../../containers/STech/Product/SubCategory/NewSubCategory";
import SubCategoryList from "../../containers/STech/Product/SubCategory/SubCategoryList";
import NewAttribute from "../../containers/STech/Product/Attribute/NewAttribute";
import AttributeList from "../../containers/STech/Product/Attribute/AttributeList";
import NewProduct from "../../containers/STech/Product/ProductInfo/NewProduct";
import ProductList from "../../containers/STech/Product/ProductInfo/ProductList";

const Product = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/categories`} component={CategoryList} />
        <Route path={`${match.url}/newcategory`} component={NewCategory} />
        <Route path={`${match.url}/editcategory/:id`} component={NewCategory} />
        <Route path={`${match.url}/newsubcategory`} component={NewSubCategory} />
        <Route path={`${match.url}/subcategories`} component={SubCategoryList} />
        <Route path={`${match.url}/editsubcategory/:id`} component={NewSubCategory} />
        <Route path={`${match.url}/newattribute`} component={NewAttribute} />
        <Route path={`${match.url}/attributes/`} component={AttributeList} />
        <Route path={`${match.url}/editattribute/:id`} component={NewAttribute} />
        <Route path={`${match.url}/newproduct`} component={NewProduct} />
        <Route path={`${match.url}/products`} component={ProductList} />
        <Route path={`${match.url}/editproduct/:id`} component={NewProduct} />
    </Switch>
);

export default Product;