export const FinalDataWithImage = (data = [], keys = []) => {
    let formData = new FormData();
    for(let i in data) {
        console.log("Values1", i)
        if(keys.includes(i)){
            console.log("Values2", data[i])
            if(data[i].length > 0) {
                data[i].forEach((file) => {
                    formData.append(i+'[]', file.originFileObj)
                });
            }
            else{
                formData.append(i, "")
            }
        }
        else {
            formData.append(i, data[i])
        }
    }

    return formData;
}