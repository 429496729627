import { Form, Input } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl';

const InputTextArea = ({
                           label = "",
                           name = "",
                           placeholder = "",
                           value = "",
                           rows = 1,
                           required = false,
                           index,
                           ...arg
}) => {
    const intl = useIntl();

    return (
        <>
            <Form.Item
                rules={[{ required: required }]}
                name={index+1 ? [index, name ] : name}
                {...arg}
                label={label === "" ? "" : intl.formatMessage({ id: label })}
                className='form__input-container'

            >
                <Input.TextArea value={value} placeholder={placeholder === "" ? "" : intl.formatMessage({ id: placeholder })} rows={rows} />
            </Form.Item>
        </>
    )
}

export default InputTextArea