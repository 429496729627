import React from "react";
import {useIntl} from "react-intl";
import {Form, Input} from "antd";

const InputText = ({label="", name="", placeholder="", required=true, disabled=false, addOn="", index, ...arg }) => {
    const intl = useIntl();

    return (
        <>
            <Form.Item
                label={label === "" ? "" : intl.formatMessage({ id: label })}
                name={index+1 ? [index, name ] : name}
                rules={[{ required: required }]}
                extra={addOn === "" ? "" : intl.formatMessage({ id: addOn })}
                {...arg}
            >
                <Input.Password
                    disabled={disabled}
                    placeholder={placeholder === "" ? "" : intl.formatMessage({ id: placeholder })}
                />
            </Form.Item>
        </>
    )
}

export default InputText;