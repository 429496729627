import React from 'react';
import {Button, Popover} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";

const Action = ({recordID, isEditShow, isDeleteShow, editURL, deleteURL, apiURL, refetchQuery}) => {
    const content = (
        <div>
            {
                isEditShow && <EditButton recordID={recordID} editUrl={editURL} />
            }
            {
                isDeleteShow &&
                <DeleteButton recordID={recordID} deleteUrl={deleteURL} apiUrl={apiURL} refetchQuery={refetchQuery}/>
            }
        </div>
    );

    return(
        <Popover content={content} trigger="hover">
            <Button size="small" className="btn__action"><MoreOutlined /></Button>
        </Popover>
    )
}

export default Action;