import React, {Fragment} from "react";
import InputFields from "../components/FormFields/InputFields";
import {Col, Divider} from "antd";

const FormFields = ({ jsonData, type }) => {
    console.log("json",jsonData)
    return jsonData?.map((item, index) => (
            <Fragment key={index}>
                {
                    item?.dividerTitle && item?.inputs &&
                        <Col span={24}>
                            <Divider orientation="left" style={{ margin: '8px 0' }}>{item.dividerTitle}</Divider>
                        </Col>
                }
                <InputFields input={item?.inputs} type={type}/>
            </Fragment>
        ))
}

export default FormFields;