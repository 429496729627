import React from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";
import JsonMenu from './menu.json';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);

    const getNoHeaderClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
            return "gx-no-header-notifications";
        }
        return "";
    };
    const getNavStyleSubMenuClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    // JsonMenu?.map((item, index) => {
    //
    // })

    return (<>
            <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            <div className="gx-sidebar-content">
                {/*<div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>*/}
                {/*    <UserProfile/>*/}
                {/*    <AppsNavigation/>*/}
                {/*</div>*/}
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        defaultOpenKeys={[defaultOpenKeys]}
                        selectedKeys={[selectedKeys]}
                        theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                        mode="inline">

                        {
                            JsonMenu?.menu?.map((item, index) =>
                                // console.log("main", item)
                                item?.submenu ? (
                                    <SubMenu key={item?.url} popupClassName={getNavStyleSubMenuClass(navStyle)}
                                             title={<span><i className={item?.icon}/><span><IntlMessages id={item.label}/></span></span>}
                                    >
                                        {
                                            item?.submenu?.map((sub, ind) =>
                                                // console.log("main", sub)
                                                sub?.submenu ?
                                                    <SubMenu key={sub?.url+sub.id} popupClassName={getNavStyleSubMenuClass(navStyle)} title={sub?.label}>
                                                        {sub?.submenu?.map((menu, indx) =>
                                                            <Menu.Item key={menu?.url}>
                                                                <Link to={`/${item?.url}/${menu?.url}`}>
                                                                    {/*<i className="icon icon-crm"/>*/}
                                                                    <span><IntlMessages id={menu?.label}/></span>
                                                                </Link>
                                                            </Menu.Item>
                                                        )

                                                        }
                                                    </SubMenu>
                                                    :

                                            <Menu.Item key={sub?.url}>
                                                <Link to={`/${item.url}/${sub?.url}`}>
                                                    {/*<i className="icon icon-crm"/>*/}
                                                    <span><IntlMessages id={sub?.label}/></span>
                                                </Link>
                                            </Menu.Item>

                                            )
                                        }

                                    </SubMenu>
                                ) :
                                <Menu.Item key={item?.url}>
                                    <Link to={`/${item?.url}`}>
                                        <i className={item?.icon}/>
                                        <span><IntlMessages id={item?.label}/></span>
                                    </Link>
                                </Menu.Item>
                            )
                        }


                        {/*<Menu.Item key="main/dashboad">*/}
                        {/*    <Link to="/main/widgets"><i className="icon icon-widgets"/>*/}
                        {/*        /!*<span><IntlMessages id="sidebar.widgets"/></span>*!/*/}
                        {/*        <span><IntlMessages id="Dashboard"/></span>*/}
                        {/*    </Link>*/}
                        {/*</Menu.Item>*/}

                        {/*<SubMenu key="product" popupClassName={getNavStyleSubMenuClass(navStyle)}*/}
                        {/*         title={<span><i className="icon icon-dasbhoard"/><span><IntlMessages id="Product"/></span></span>}*/}
                        {/*>*/}
                        {/*    <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} title="Category">*/}
                        {/*        <Menu.Item key="product/categories">*/}
                        {/*            <Link to="/product/categories">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Categories"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*        <Menu.Item key="product/newcategory">*/}
                        {/*            <Link to="/product/newcategory">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Add Category"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*    </SubMenu>*/}

                        {/*    <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} title="Sub Category">*/}
                        {/*        <Menu.Item key="product/subcategories">*/}
                        {/*            <Link to="/product/subcategories">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Sub Categories"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*        <Menu.Item key="product/newsubcat">*/}
                        {/*            <Link to="/product/newsubcategory">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Add Sub Category"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*    </SubMenu>*/}

                        {/*    <Menu.Item key="product/dashboard/crm">*/}
                        {/*        <Link to="/product/dashboard/crm">*/}
                        {/*            /!*<i className="icon icon-crm"/>*!/*/}
                        {/*            <span><IntlMessages id="Variations"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*    <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} title="Product Info">*/}
                        {/*        <Menu.Item key="main/dashboard/listing">*/}
                        {/*            <Link to="/main/dashboard/listing">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Products"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*        <Menu.Item key="main/dashboard/listing">*/}
                        {/*            <Link to="/main/dashboard/listing">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Add Product"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*    </SubMenu>*/}

                        {/*    <SubMenu popupClassName={getNavStyleSubMenuClass(navStyle)} title="Promo Offer">*/}
                        {/*        <Menu.Item key="main/dashboard/listing">*/}
                        {/*            <Link to="/main/dashboard/listing">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Offers"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*        <Menu.Item key="main/dashboard/listing">*/}
                        {/*            <Link to="/main/dashboard/listing">*/}
                        {/*                <i className="icon icon-listing-dbrd"/>*/}
                        {/*                <span><IntlMessages id="Create New Offer"/></span>*/}
                        {/*            </Link>*/}
                        {/*        </Menu.Item>*/}
                        {/*    </SubMenu>*/}

                        {/*</SubMenu>*/}

                        {/*<SubMenu key="Customer" popupClassName={getNavStyleSubMenuClass(navStyle)}*/}
                        {/*         title={<span><i className="icon icon-dasbhoard"/><span><IntlMessages id="Customer"/></span></span>}*/}
                        {/*>*/}
                        {/*    <Menu.Item key="main/dashboard/crypto">*/}
                        {/*        <Link to="/main/dashboard/crypto">*/}
                        {/*            <i className="icon icon-crypto"/>*/}
                        {/*            <span><IntlMessages id="Customers"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*    <Menu.Item key="main/dashboard/crm">*/}
                        {/*        <Link to="/main/dashboard/crm">*/}
                        {/*            <i className="icon icon-crm"/>*/}
                        {/*            <span><IntlMessages id="New Customer"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*</SubMenu>*/}

                        {/*<SubMenu key="Customer" popupClassName={getNavStyleSubMenuClass(navStyle)}*/}
                        {/*         title={<span><i className="icon icon-dasbhoard"/><span><IntlMessages id="Vendor"/></span></span>}*/}
                        {/*>*/}
                        {/*    <Menu.Item key="main/dashboard/crypto">*/}
                        {/*        <Link to="/main/dashboard/crypto">*/}
                        {/*            <i className="icon icon-crypto"/>*/}
                        {/*            <span><IntlMessages id="Lists"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*    <Menu.Item key="main/dashboard/crm">*/}
                        {/*        <Link to="/main/dashboard/crm">*/}
                        {/*            <i className="icon icon-crm"/>*/}
                        {/*            <span><IntlMessages id="New Vendor"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*    <Menu.Item key="main/dashboard/crm">*/}
                        {/*        <Link to="/main/dashboard/crm">*/}
                        {/*            <i className="icon icon-crm"/>*/}
                        {/*            <span><IntlMessages id="Vendor Bill"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*</SubMenu>*/}

                        {/*<SubMenu key="Customer" popupClassName={getNavStyleSubMenuClass(navStyle)}*/}
                        {/*         title={<span><i className="icon icon-dasbhoard"/><span><IntlMessages id="Order"/></span></span>}*/}
                        {/*>*/}
                        {/*    <Menu.Item key="main/dashboard/crypto">*/}
                        {/*        <Link to="/main/dashboard/crypto">*/}
                        {/*            <i className="icon icon-crypto"/>*/}
                        {/*            <span><IntlMessages id="Orders"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*    <Menu.Item key="main/dashboard/crm">*/}
                        {/*        <Link to="/main/dashboard/crm">*/}
                        {/*            <i className="icon icon-crm"/>*/}
                        {/*            <span><IntlMessages id="Pre-Orders"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*</SubMenu>*/}

                        {/*<SubMenu key="Customer" popupClassName={getNavStyleSubMenuClass(navStyle)}*/}
                        {/*         title={<span><i className="icon icon-dasbhoard"/><span><IntlMessages id="Shop"/></span></span>}*/}
                        {/*>*/}
                        {/*    <Menu.Item key="main/dashboard/crypto">*/}
                        {/*        <Link to="/main/dashboard/crypto">*/}
                        {/*            <i className="icon icon-crypto"/>*/}
                        {/*            <span><IntlMessages id="All Shop"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*    <Menu.Item key="main/dashboard/crm">*/}
                        {/*        <Link to="/main/dashboard/crm">*/}
                        {/*            <i className="icon icon-crm"/>*/}
                        {/*            <span><IntlMessages id="New Shop"/></span>*/}
                        {/*        </Link>*/}
                        {/*    </Menu.Item>*/}
                        {/*</SubMenu>*/}

                        {/*<Menu.Item key="main/widgets">*/}
                        {/*    <Link to="/main/widgets"><i className="icon icon-widgets"/>*/}
                        {/*        /!*<span><IntlMessages id="sidebar.widgets"/></span>*!/*/}
                        {/*        <span><IntlMessages id="Logout"/></span>*/}
                        {/*    </Link>*/}
                        {/*</Menu.Item>*/}

                    </Menu>
                </CustomScrollbars>
            </div>
        </>);
};

SidebarContent.propTypes = {};
export default SidebarContent;

